<template>
    <v-card class="mt-2 ml-2 mr-2">
        <FTemplateRegGroupTable class="pl-2 pr-2" ref="refTemplateRegGroupTable"/>
    </v-card>
  </template>
  
  <script>
  import FTemplateRegGroupTable from "@/components/admin_setup/template-reg-group/FTemplateRegGroupTable";
  
  export default {
    name: "TemplateRegGroup",
    components: {
      FTemplateRegGroupTable
    },
    data() {
      return {
        tabTitle: 'TemplateRegGroup'
      }
    },
    computed: {
    },
    methods: {
      tabTemplateRegGroupClick(){
        this.$refs.refTemplateRegGroupTable.fetchParent()
      },
  
    },
  
  
  }
  </script>
  
  <style scoped>
  </style>