<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
    >
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar dark color="brown" class="color-gradient-1" dense>
            <v-btn icon dark @click="closeForm">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM'">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM'"> BARU</span>

            <span
              class="ml-2 mr-2 font-weight-medium grey--text"
              v-show="isItemModified"
            >
              |
            </span>
            <v-chip
              class="ma-2"
              color="warning"
              outlined
              x-small
              v-show="isItemModified"
            >
              <v-icon left> mdi-pencil </v-icon>
              modified
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save"
                :disabled="!valid || isItemModified === false"
                class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-title> </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="4" sm="6">
                  <v-container class="pa-2 text-center">
                    <v-row class="fill-height" align="center">
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="align-self-center"
                          :elevation="hover ? 10 : 1"
                          :class="[
                            { 'on-hover': hover },
                            hover
                              ? 'card-hover-opacity'
                              : 'card-not-hover-opacity',
                          ]"
                        >
                          <v-img
                            :lazy-src="lookupImageUrlLazy(itemModified)"
                            :src="lookupImageUrl(itemModified)"
                            height="250px"
                            width="250px"
                            contain
                          >
                            <v-card-title
                              class="text-h6 white--text fill-height"
                            >
                              <v-row
                                class="fill-height flex-column"
                                justify="space-between"
                              >
                                <v-spacer />
                                <div class="align-self-center">
                                  <v-btn
                                    :class="{ 'show-btns': hover }"
                                    :color="transparent"
                                    icon
                                    large
                                    dark
                                    outlined
                                    @click="showDialogUpload"
                                  >
                                    <v-icon
                                      :class="{ 'show-btns': hover }"
                                      :color="transparent"
                                      large
                                    >
                                      mdi-upload
                                    </v-icon>
                                  </v-btn>
                                </div>
                              </v-row>
                            </v-card-title>
                          </v-img>
                        </v-card>
                      </v-hover>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="8" sm="6">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                          v-model="itemModified.kode1"
                          label="Kode 1"
                          :rules="rulesNotEmtpy"
                          dense
                          hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="itemModified.description"
                        label="Deskripsi"
                        :rules="rulesNotEmtpy"
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="9">
                      <v-autocomplete
                        v-model="itemModified.fdivisionBean"
                        :items="itemsFDivision"
                        :rules="rulesNotEmtpy"
                        item-value="id"
                        item-text="description"
                        auto-select-first
                        small-chips
                        deletable-chips
                        color="blue-grey lighten-2"
                        label="Divisi/OPD"
                        :hint="`Divisi/OPD : ${
                          lookupFDivision(itemModified.fdivisionBean)
                            .description
                        }`"
                        persistent-hint
                        single-line
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <v-switch
                        v-model="itemModified.statusActive"
                        :label="
                          itemModified.statusActive ? 'Aktif' : 'Non-Aktif'
                        "
                        x-small
                        hide-details
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-chip
              class="ml-4"
              color="error"
              outlined
              close
              small
              v-show="formDialogOptions.errorMessage"
            >
              {{ formDialogOptions.errorMessage }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="closeForm"
              class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="save"
              :disabled="!valid || isItemModified === false"
              class="hidden-sm-and-down mr-10"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>
      <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
      ></UploadImageDialog>

      <v-snackbar v-model="snackbar">
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>
  
  <script>
import UploadImageDialog from "@/components/utils/UploadImageDialog";
import FTemplateRegGroupService from "@/services/apiservices/f-template-reg-group-service";
import FTemplateRegGroupDokumen from "@/models/f-template-reg-dokumen";
import FTemplateRegGroupDokumenService from "@/services/apiservices/f-template-reg-dokumen-service";
import CloseConfirmDialog from "@/components/utils/CloseConfirmDialog";
import FormMode from "@/models/form-mode";
import FTemplateRegGroup from "@/models/f-template-reg";
import FileService from "@/services/apiservices/file-service";
import { format, parseISO } from "date-fns";

export default {
  name: "FTemplateRegGroupDialog",
  components: {
    CloseConfirmDialog,
    UploadImageDialog,
  },
  props: {},
  data() {
    return {
      formModeFTemplateRegGroupDokumen: "",
      title: "TEMPLATE REGISTRASI",
      snackBarMesage: "",
      snackbar: false,
      multiSelect: false,
      imageOverlay: false,
      selectedItems: [],
      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },
      date: format(parseISO(new Date().toISOString()), "dd-MMM-yyyy"),
      menuDateOpen: false,
      menuDateTo: false,

      selectedItemIndex: -1,
      itemDefault: "",

      valid: false,
      rulesLength: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      transparent: "rgba(255, 255, 255, 0)",
      // itemsPointSoal: [0, 25, 30, 50, 75, 100],
      fTemplateRegGroupDokumens: [],
      itemFTemplateRegGroupId: undefined,
      itemSelectedIndex: -1,
      newFTemplateRegGroupId: 0,
    };
  },
  computed: {
    openFrom: {
      get() {
        try {
          const inputDate = new Date(this.itemModified.openFrom);
          return inputDate.toISOString().split("T")[0];
        } catch (e) {
          return new Date().toISOString().split("T")[0];
        }
      },
      set(value) {
        this.itemModified.openFrom = value;
      },
    },
    openTo: {
      get() {
        try {
          const inputDate = new Date(this.itemModified.openTo);
          return inputDate.toISOString().split("T")[0];
        } catch (e) {
          return new Date().toISOString().split("T")[0];
        }
      },
      set(value) {
        // Add logic here if needed when setting the value
        this.itemModified.openTo = value;
      },
    },

    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
    itemModified: {
      get() {
        return this.$store.state.FTemplateRegModule.itemModified;
      },
      set(value) {
        this.$store.dispatch("FTemplateRegModule/updateItemModified", value);
      },
    },
    formMode: {
      get() {
        return this.$store.state.FTemplateRegModule.formMode;
      },
      set(value) {
        this.$store.dispatch("FTemplateRegGroupModule/updateFormMode", value);
      },
    },
    itemsFDivision: {
      get() {
        return this.$store.state.FTemplateRegModule.itemsFDivision;
      },
      set(value) {
        this.$store.dispatch("FTemplateRegGroupModule/updateItemsFDivision", value);
      },
    },
  },
  watch: {
    // itemModified:{
    //   handler: function (newValue){
    //     if (newValue){
    //     }
    //   }
    // }
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    computedDateFormattedDatefns(value) {
      return value ? format(parseISO(value), "dd MMMM yyyy") : "";
    },
    showDialogUpload() {
      if (
        this.itemModified.kode1 !== undefined &&
        this.itemModified.description !== undefined &&
        this.itemModified.fdivisionBean !== undefined
      ) {
        if (this.itemModified.id === 0) {
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly();
        }
        this.$refs.refUploadDialog.showDialog();
      } else {
        this.snackBarMesage = "Kode, Deskripsi dan Divisi harus diisi dahulu";
        this.snackbar = true;
      }
    },
    completeUploadSuccess: function (val) {
      if (
        this.itemModified.avatarImage !== undefined &&
        this.itemModified.avatarImage !== ""
      ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
          () => {
            // console.log(response.data)
          },
          (error) => {
            console.log(error.response);
          }
        );
      }
      // console.log(val.fileName);
      if (val.fileName !== "") {
        this.$refs.refUploadDialog.closeDialog();
        this.itemModified.avatarImage = val.fileName;
        // console.log(this.itemModified);
        // console.log(this.itemModified.avatarImage);
        this.saveUpdateOnly();
      }
    },

    showDialog(selectedIndex, item) {
      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        (this.itemDefault = new FTemplateRegGroup(0, "", "")),
          (this.itemModified = new FTemplateRegGroup(0, "", "")),
          (this.selectedIndex = -1);
        /**
         * ##++ Tambahan untuk Child
         */
        try {
          this.$refs.refFTemplateRegGroupDokumenTable.fTemplateRegGroupDokumens = [];
        } catch (e) {
          // console.log("undefined");
        }
      }
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    save() {
      if (this.isItemModified === false) {
        //Close aja
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.formMode === FormMode.EDIT_FORM) {
          FTemplateRegGroupService.updateFTemplateRegGroup(this.itemModified).then(
            () => {
              // console.log(response.data)
              this.$emit("eventFromFormDialogEdit", this.itemModified);
            },
            (error) => {
              // console.log(error);
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        } else {
          // console.log(JSON.stringify(this.itemModified))

          FTemplateRegGroupService.createFTemplateRegGroup(this.itemModified).then(
            (response) => {
              this.$emit("eventFromFormDialogNew", response.data);
              // console.log(response.data)
            },
            (error) => {
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        }
      }
    },
    saveCreateOnly() {
      if (this.formMode === FormMode.NEW_FORM) {
        FTemplateRegGroupService.createFTemplateRegGroup(this.itemModified).then(
          (response) => {
            /**
             * dipaksa Save dan Update Dahulu
             */
            // this.initializeEditMode(response.data)
            this.$emit("update:formMode", FormMode.EDIT_FORM);
            this.itemModified.id = response.data.id;
            // console.log("save berhasil")
            // console.log("id F template-reg setelah save : " + this.itemModified.id)
            this.newFTemplateRegGroupId = response.data.id;
            // console.log("id new terbaru setelah save : " + this.newFTemplateRegGroupId)
          },
          (error) => {
            this.formDialogOptions.errorMessage = error.response.data.message;
          }
        );
      }
    },
    saveUpdateOnly() {
      FTemplateRegGroupService.updateFTemplateRegGroup(this.itemModified).then(
        () => {
          // console.log(response.data);
        },
        (error) => {
          // console.log(error);
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      this.newFTemplateRegGroupId = item.id;
      // console.log("ini ID edit " + this.newFTemplateRegGroupId)
      this.formDialogOptions.errorMessage = "";

      FTemplateRegGroupService.getFTemplateRegGroupById(item.id).then(
        (response) => {
          // console.log(response.data)
          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;
          this.$refs.refFTemplateRegGroupDokumenTable.fetchFTemplateRegGroupDokumenByFTemplateRegGroup(
            this.itemModified
          );
        },
        (error) => {
          console.log(error);
        }
      );
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupImageUrl(item) {
      // console.log(JSON.stringify(item))
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_medium(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },

    closeDialog() {
      this.formModeFTemplateRegGroupDokumen = "";
      this.$refs.refFormDialog.setDialogState(false);

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FTemplateRegGroupDokumen());
        this.itemSelectedIndex = -1;
      });
    },
    saveDataEdit(item) {
      this.itemSelected = item;
      try {
        Object.assign(
          this.fTemplateRegGroupDokumens[this.itemSelectedIndex],
          this.itemSelected
        );
      } catch (e) {
        this.fetchFTemplateRegGroupDokumen();
        e.toString();
      }
      this.closeDialog();
    },
    saveDataNew(itemFromRest) {
      itemFromRest.ftemplateRegBean = this.itemModified.id;

      this.itemSelected = itemFromRest;
      FTemplateRegGroupDokumenService.createFTemplateRegGroupDokumen(itemFromRest).then(
        () => {},
        (error) => {
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
      this.closeDialog();
      /**
       * Setiap data baru harus terlihat
       */
      this.fetchFTemplateRegGroupDokumen();
    },
  },
};
</script>
  
  <style scoped>
.card-hover-opacity {
  opacity: 0.4;
}
.card-not-hover-opacity {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>